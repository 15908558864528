import React from 'react'

import './Services.css'

const Services = () => {
    return (
        <div className="services">
            <h1>Services</h1>
            <section>
                <p>If a photographic style or sector is not detailed below, please contact us to discuss your requirements.</p>
            </section>
            <section>
                <h3>Prospectus photography</h3>
                <p>School, College and University prospectus work is undertaken, for either print or web. We can now provide broadcast quality video filming for video prospectus for mailing or website use.</p>
            </section>
            <section>
                <h3>Corporate</h3>
                <p>All corporate work is undertaken, from product photography to conferences and marketing material.</p>
            </section>
            <section>
                <h3>Portaiture</h3>
                <p>Lifestyle portraiture, at work or home, with an understanding innovative style</p>
            </section>
        </div>
    )
}

export default Services