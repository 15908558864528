import React from 'react'
import Footer from '../Footer'

import './ContentPage.css'

const ContentPage = (props) => {
    const {children} = props

    return (
        <div className={props.className}>
            <div>
                {children}
            </div>
            <Footer/>
        </div>
    )
}

export default ContentPage