import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltCircleDown, faArrowAltCircleUp } from '@fortawesome/free-solid-svg-icons'
import {
  Link
} from "react-router-dom"

import Paths from './paths' 

import './Navigation.css'

const SmallScreenNavigation = () => {
    return (
        <nav className='navmenu-small'>
            <ul>
                <Link to={Paths.Home}><li>The Still-Image Company</li></Link>
            </ul>
            <ul>
                <Link to={Paths.AboutUs}><li>About Us</li></Link>
                <Link to={Paths.Clients}><li>Clients</li></Link>
                <Link to={Paths.Services}><li>Services</li></Link>
                <Link to={Paths.ContactUs}><li>Contact Us</li></Link>
            </ul>
        </nav>
    )
}

const Navigation = (props) => {

    const [isExpanded, setIsExpanded] = useState(true)
    const [menuHeight, setMenuHeight] = useState(0)

    const handleResize = () => {
        const navMenuElem = document.getElementById('navmenu')
        if (navMenuElem){
            setMenuHeight(navMenuElem.offsetHeight)
        }
    }
    
    useEffect(() => {
        handleResize()
    }, [])

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    })

    const { isBigScreen } = props

    if (!isBigScreen)
        return SmallScreenNavigation()

    const positionStyling = () => {
        const translation = isExpanded ? 0 : -menuHeight;
        return {
            transform: 'translateY(' + translation + 'px)'
          };
    }

    return (
        <div className="nav-positioned">
            <nav id='navmenu' className="navigation-menu" style={positionStyling()}>
                <ul>
                    <Link to={Paths.Home}><li className="company-name">The Still-Image Company</li></Link>
                    <Link to={Paths.AboutUs}><li>About Us</li></Link>
                    <Link to={Paths.Clients}><li>Clients</li></Link>
                    <Link to={Paths.Services}><li>Services</li></Link>
                    <Link to={Paths.ContactUs}><li>Contact Us</li></Link>
                </ul>
            </nav>
            <div className="bottom-of-nav-menu"  style={positionStyling()}>
                <div className="arrow-down">
                    <FontAwesomeIcon size="lg" icon={isExpanded ? faArrowAltCircleUp : faArrowAltCircleDown} className="expander" onClick={() => setIsExpanded(!isExpanded)} />
                </div>
                <div className="arrow-down outline"></div>
            </div>
        </div>
    )
}

export default Navigation