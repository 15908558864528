import React from 'react'

import './Footer.css'

const Footer = () => {
    const currentYear = new Date().getFullYear()
    return <div className="footer">
        <p>© Keith Heppell {currentYear}</p>
    </div>
}

export default Footer