import React from 'react';
import './App.css';
import BackgroundSlider from './BackgroundSlider'
import Navigation from './Navigation'
import ContentPage from './Content/ContentPage'
import AboutUs from './AboutUs'
import Clients from './Clients'
import Services from './Services'
import ContactUs from './ContactUs'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom"
import Paths from './paths'
import { useMediaQuery } from 'react-responsive'

function App() {

  const isBigScreen = useMediaQuery({
    query: '(min-device-width: 820px)'
  })

  return (
    <Router>
      <div className="App">
        <Navigation isBigScreen={isBigScreen} className="navigation" />
        <BackgroundSlider isBigScreen={isBigScreen}/>
          <Switch>
            <Route exact path={Paths.Home}></Route>
            <ContentPage className={isBigScreen ? "content": "content-small"}>
              <Route path={Paths.AboutUs}>
                <AboutUs/>
              </Route>
              <Route path={Paths.Services}>
                <Services/>
              </Route>
              <Route path={Paths.Clients}>
                <Clients/>
              </Route>
              <Route path={Paths.ContactUs}>
                <ContactUs/>
              </Route>
            </ContentPage>
          </Switch>
      </div>
    </Router>
  );
}

export default App;
