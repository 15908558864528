import React from 'react'

import './Clients.css'

const Clients = () => {
    return (
        <div className="clients">
            <h1>Clients</h1>
            <section>
                <p>The following are some of our clients:</p>
            </section>
            <section>
                <h3>Prospectus</h3>
                <p>One of our specilaities is Prospectus Photography.</p>
                <p>You will find our imaging in the Under Graduate Prospectus of Cambridge University and the Graduate Studies Prospectus of Cambridge University, The individual College Prospectus of Magdalene College, St John’s, Fitzwilliam, Jesus, Robinson, Emmanuel, Downing, Churchill, Newnham and Sidney Sussex. As well as University bid publications, CAM, Annual Reports, and websites.</p>
            </section>
        </div>
    )
}

export default Clients