import React from 'react'

import './ContactUs.css'

const ContactUs = () => {
    return (
        <div className="contact-us">
            <h1>Contact Us</h1>
            <section>
                <p>For more information or questions about our products & services, please contact us using the details below.</p>
            </section>
            <section>
                <h3>Mobile</h3>
                <p>Mobile 1: +44 7970 434362</p>
                <p>Mobile 2: +44 7843 368502</p>
            </section>
            <section>
                <h3>Email</h3>
                <p>Email 1: <a href="mailto:keith@keithheppell.co.uk">keith@keithheppell.co.uk</a></p>
                <p>Email 2: <a href="mailto:keithheppell@mac.com">keithheppell@mac.com</a></p>
            </section>
        </div>
    )
}

export default ContactUs