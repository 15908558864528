import React from 'react'

import './AboutUs.css'

const AboutUs = () => {
    return (
        <div className="about-us">
            <h1>About Us</h1>
            <section>
                <p>Located just outside Cambridge, we provide innovative imaging solutions, on time, on budget, accurately representing the brief. 
                    Our sectors are Prospectus, Corporate, PR, Portraiture, Conference and Industrial.</p>
            </section>
            <section>
                <h3>What drives us</h3>
                <p>We want to create lasting, orginal images and video which reflect the needs of the client.</p>
            </section>
            <section>
                <h3>People</h3>
                <p>Our photographers are based around the country enabling us to react quickly to clients demands and provide an effective economic solution.</p>
            </section>
            <section>
                <h3>Company</h3>
                <p>We have over thirty years experience providing high quailty imaging for print and web.</p>
            </section>
        </div>
    )
}

export default AboutUs