import React from 'react'
import BackgroundSlider from 'react-background-slider'

import scientist from './images/agsp008.jpg'
import cathedral from './images/ELYafter-dark-event-at-the-cathedral004.jpg'
import squirrels from './images/ELYsquirrels-cares-haddenham006.jpg'
import upware from './images/ELYupware-general-view002.jpg'
import afterburners from './images/NEWafterburners001.jpg'
import motorshow from './images/NEWtwo-counties-motorshow009.jpg'

import cathedralSmallWidth from './images/cathedral-small-width.jpg'
import squirrelsSmallWidth from './images/squirrels-small-width.jpg'
import afterburnersSmallWidth from './images/afterburners-small-width.jpg'
import motorshowSmallWidth from './images/motorshow-small-width.jpg'

const backgroundSlider = (props) => {
    let images = [scientist, cathedral, squirrels, upware, afterburners, motorshow]

    if (!props.isBigScreen) {
      images = [cathedralSmallWidth, squirrelsSmallWidth, upware, afterburnersSmallWidth, motorshowSmallWidth]
    }

    return (
        <BackgroundSlider
      images={images}
      duration={10} transition={2} />
    )
}

export default backgroundSlider